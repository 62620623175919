.containerHeader {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: auto;
}
.wrapFour {
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.h1Fo {
  color: #00aef7;
  font-size: 180px;
  margin-bottom: 0;
  margin-top: 0;
}
.pFor {
  font-size: 20px;
  color: white;
}
.relocateTo {
  padding-left: 30px;
  padding-right: 30px;
  color: white;
  height: 40px;
  width: auto;
  background: #00aef7;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
