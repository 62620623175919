body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.headerStyle {
  position: relative;
  display: flex;

  top: 0px;
  width: 100%;
  height: 100vh;
}
body {
  display: flex;
  flex-direction: column;
}
.containerHeader {
  position: relative;
  z-index: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

nav ul {
  display: flex;
  list-style: none;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
}
nav ul li a {
  position: relative;
  padding-right: 1.8vw;
  padding-left: 1.8vw;
  border-right: 2px solid;
  font-family: "Jost", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 90px;
  color: white;
}
nav ul li a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background: #00aef7;
  z-index: -1;

  transition: all 0.5s ease-in-out;
}
nav ul li a:hover::before {
  width: 100%;
}

nav ul li:last-child a {
  border-right: 0;
}
nav span {
  color: #00aef7;
}

a {
  text-decoration: none;
}
nav h1 {
  display: flex;
  padding-left: 3.125vw;
  font-family: "Jost", sans-serif;
  color: white;
}
nav .iconWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  margin-right: 3.125vw;
  border: 1px solid white;
  border-radius: 3px;
  background: white;
}
nav .iconWrap .icon {
  font-size: 20px;
  cursor: pointer;
}

nav .iconWrap:hover {
  width: 40px;
  height: 40px;
  margin-right: 2.917vw;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  box-shadow: 1px 5px 10px #00aef7;
}
nav .lang {
  display: flex;
  justify-content: center;
  align-items: center;
}
nav .lang h4 a {
  display: flex;
  color: white;
  cursor: pointer;
  padding-right: 10px;
}
#titleFirst {
  position: relative;

  display: flex;
  padding: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 800px;
  width: 100%;
}
.wrapWithEr {
  position: absolute;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}
#titleFirst .titleFirst {
  display: flex;
  justify-self: end;
  height: 100px;
}
.h1Wrl {
  color: white;
  font-size: 40px;
  margin-bottom: 50px;
}
.h2Wrl {
  color: white;
  font-size: 20px;
  width: 800px;
  text-align: center;
  margin-bottom: 100px;
}
#titleFirst button {
  position: relative;
  width: 130px;
  height: 40px;
  background: none;
  border: 0;
  color: white;
  font-size: 23px;
  font-weight: bold;
  cursor: pointer;
  background: #00aef7;
}
#titleFirst button a {
  color: white;
}

#productList {
  padding: 0;
  height: 1000px;
  width: 100%;
  position: relative;
  z-index: 1;
  margin-top: 100px;
}
#productList h2 {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  font-size: 40px;
  color: white;
}
#productList .productList {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 80px;
}
#productList .productList div {
  display: "flex";
  overflow: hidden;
  position: relative;
  width: 25%;
  height: 300px;
  border: 2px solid white;
  margin-right: 2%;
  margin-top: 2%;
  transition: all 300ms ease-in-out;
}

#productList .productList div:hover {
  height: 410px;
  background: white;
}
#productList .productList div h1 {
  position: absolute;
  top: 20vh;
  left: 1.563vw;
  color: white;
  font-size: 25px;
  font-family: "Jost", sans-serif;
  transition: all 300ms ease-in-out;
}

#productList .productList div:hover h1 {
  top: 260px;
  color: black;
}
#productList .productList div p {
  position: absolute;
  top: 300px;
  left: 30px;
  color: black;
  font-size: 20px;
  font-family: "Jost", sans-serif;
}
#productList .productList div h2 {
  position: absolute;
  top: 0px;
  left: 30px;
  color: white;
  font-size: 30px;
  font-family: "Jost", sans-serif;
}
#productList .productList div:hover h2 {
  color: black;
}
#productList .productList div h3 {
  position: absolute;
  overflow: hidden;
  top: 0px;
  left: 380px;
  margin-top: 0px;
  color: white;
  font-size: 70px;
  font-family: "Jost", sans-serif;
  transition: all 300ms ease-in-out;
}
#productList .productList div:hover h3 {
  left: 150px;
  color: #eeeeee;
}
#about {
  width: 100%;
  padding-top: 80px;
  height: auto;
  position: relative;
  z-index: 1;
}
#about h3 {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  font-size: 60px;
  color: white;
}
#about .about {
  display: flex;
  justify-content: center;
}
.about .block {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 25%;

  margin-left: 30px;
}
.about .block .minblock {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  background: white;
  border-radius: 40px;
}
.about .block .minblock::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  border-radius: 40px;
  background: #00aef7;
}
.about .block .minblock:hover::before {
  transition: all 0.5s ease-in-out;
  width: 100%;
}
.minblock h2 {
  color: black;
  z-index: 1;
  font-size: 40px;
  font-family: "Jost", sans-serif;
}
.about .block h1 {
  font-size: 40px;
  font-family: "Jost", sans-serif;
  color: white;
}

.about .block p {
  font-size: 18px;
  font-family: "Jost", sans-serif;
  color: white;
}
.technologies {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.technologies h4 {
  font-size: 60px;
  font-family: "Jost", sans-serif;
  color: white;
}
.techIcon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.techIcon .icon {
  display: flex;
  font-size: 80px;
  margin-right: 20px;
  margin-bottom: 30px;
}
.techIcon .icon:hover {
  font-size: 100px;
  transition: all 0.3s ease-in-out;
}

nav .outLineMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  margin-left: 10px;
  border: 1px solid white;
  border-radius: 3px;
  background: white;
  flex-shrink: 0;
}
/*sddddddddddddddddddddddddddddd*/
#mailSend {
  width: 100%;
  height: auto;
  position: relative;
  z-index: 1;
}
.containerInfo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.columInfo {
  display: flex;
  width: 40%;
  height: 500px;
  margin-bottom: 100px;
  justify-content: space-around;
}
.columInfoTe h2 {
  font-size: 30px;
  margin-top: 150px;
  color: white;
  font-weight: bold;
  font-family: "Jost", sans-serif;
}
.columInfoTe strong {
  color: white;
  font-family: "Jost", sans-serif;
  font-size: 25px;
}
.columInfoTe p {
  color: white;
  font-family: "Jost", sans-serif;
}
.columInfoTe a {
  font-family: "Jost", sans-serif;
  text-decoration: none;
  font-size: 20px;
  color: white;
}
.columInfoTe h1 {
  margin-top: 20px;
}
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
}
.contact-form {
  margin: 0 auto;
  display: flex;
  width: 100%;
}
.input-fields {
  display: flex;
  flex-direction: column;
  margin-right: 4%;
}
.input-fields,
.msg {
  width: 46%;
}
.input-fields .input,
.msg textarea {
  margin: 10px 0;
  background: transparent;
  border: 0;
  border-bottom: 2px solid white;
  padding: 10px;
  color: white;
  width: 100%;
}
.msg textarea {
  height: 83%;
}
::-webkit-input-placeholder {
  color: white;
  font-weight: bolder;
}
::-moz-input-placeholder {
  color: white;
  font-weight: bolder;
}
::-ms-input-placeholder {
  color: white;
  font-weight: bolder;
}
.btn {
  position: relative;
  background: none;
  text-align: center;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 100%;
  border: none;
  font-size: 20px;
  cursor: pointer;
  text-transform: uppercase;
  height: 40px;
  font-family: "Jost", sans-serif;
}
.btn::before {
  content: "";
  width: 40%;
  z-index: -1;
  height: 100%;
  background: #00aef7;
  top: 0;
  left: 0;
  position: absolute;
}
.btn:hover::before {
  width: 100%;
  color: black;
  transition: all 0.5s ease-in-out;
  background: #00aef7;
}
footer {
  width: 100%;
  height: 40px;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
}

footer h6 {
  font-family: "Jost", sans-serif;
  font-size: 15px;
  color: white;
}

/*ccccccccccccccoooooooooooonnnnnnnnnnnntttttttttttttttaaaaaaaaaaaaacccccccccctttttttt*/
.contactDiv {
  display: flex;
  width: 100%;
  height: 80vh;
  justify-content: space-around;
  align-items: center;
}
.contactFo {
  display: flex;
  flex-direction: column;
}
.contactFo h1 {
  font-family: "Jost", sans-serif;
  color: white;
  font-size: 40px;
}
.contactFo h2 {
  font-family: "Jost", sans-serif;
  color: white;
  font-size: 35px;
}
.contactFo a {
  font-family: "Jost", sans-serif;
  color: white;
  font-size: 30px;
}

.fixedButton {
  position: fixed;
  color: white;
  top: 50vh;

  transform: rotate(-90deg);
  z-index: 2;
  background: none;
  cursor: pointer;
  font-family: "Jost", sans-serif;
  font-size: 1.5em;
  left: -1%;
  border: none;
}
.contacktAll {
  position: fixed;
  z-index: 3;
  opacity: 0.9;
  background: black;
  width: 100%;
  height: 100vh;
}
.contacktAll {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.headerContact {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  font-family: "Jost", sans-serif;
  font-size: 1.5em;
  color: white;
}
.bodyContact {
  padding-top: 10%;
  width: 70%;
}
.up {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 90%;
  top: 85vh;
  cursor: pointer;
  z-index: 2;
  width: 50px;
  height: 50px;
  background: white;
}
.pulse-button__rings {
  border: 2px solid white;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: auto;
  width: auto;

  animation-duration: 2s;
  animation-name: pulse_1;
  animation-iteration-count: infinite;
  z-index: -1;
}

.pulse-button__rings:nth-child(2) {
  animation-name: pulse_2;
}

.pulse-button__rings:nth-child(3) {
  animation-name: pulse_3;
}
@keyframes pulse_1 {
  from {
    opacity: 1;
    transform: scale(0.9, 0.9);
  }

  to {
    opacity: 0;
    transform: scale(1.3, 1.3);
  }
}

@keyframes pulse_2 {
  from {
    opacity: 1;
    transform: scale(0.8, 0.8);
  }

  to {
    opacity: 0;
    transform: scale(1.5, 1.5);
  }
}

@keyframes pulse_3 {
  from {
    opacity: 1;
    transform: scale(0.8, 0.8);
  }

  to {
    opacity: 0;
    transform: scale(1.7, 1.7);
  }
}
.menu {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 0;
  left: 0;
  width: 40%;
  height: 100vh;
  background: white;
  opacity: 0.9;
  z-index: 10;
}
.menu .ulMobile {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.liMobile a {
  border: none;

  font-family: "Jost", sans-serif;
  font-size: 2em;
  color: black;
}
@media only screen and (max-width: 479px) and (min-width: 300px) {
  #titleFirst {
    height: 400px;
  }
  .h1Wrl {
    font-size: 15px;
    width: 80%;
    margin-bottom: 40px;
    text-align: center;
  }
  .h2Wrl {
    font-size: 13px;
    width: 80%;
    margin-bottom: 40px;
    text-align: center;
  }
  #productList h2 {
    font-size: 15px;
  }
  nav .iconWrap {
    width: 0;
    height: 0;
  }
  .menu {
    width: 100%;
  }
  .contactFo h1 {
    font-family: "Jost", sans-serif;
    color: white;
    font-size: 30px;
  }
  .contactFo h2 {
    font-family: "Jost", sans-serif;
    color: white;
    font-size: 18px;
  }
  .contactFo a {
    font-family: "Jost", sans-serif;
    color: white;
    font-size: 20px;
  }
  .contactDiv {
    display: flex;
    width: 100%;
    min-height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .btn {
    position: relative;
    background: none;
    text-align: center;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    width: 100%;
    border: none;
    font-size: 15px;
    cursor: pointer;
    text-transform: uppercase;
    height: 40px;
    font-family: "Jost", sans-serif;
  }
  .input-fields {
    display: flex;
    flex-direction: column;
    width: calc(100% - 4%);
    margin-right: 4%;
  }
  .msg {
    width: calc(100% - 4%);
  }
  #mailSend {
    width: 100%;
    margin-bottom: 60px;
  }
  .titleFirst {
    width: 75%;
  }
  .titleFirst h1 {
    color: white;
    font-size: 20px;
  }
  .fixedButton {
    font-size: 1em;
    left: -5%;
    border: none;
  }
  #productList {
    padding: 0;
    height: auto;
    width: 100%;
  }
  #productList .productList div {
    display: "flex";
    overflow: hidden;
    position: relative;
    width: 80%;
    height: 340px;
    border: 2px solid white;
    margin-right: 2%;
    margin-top: 2%;
    transition: all 300ms ease-in-out;
  }
  #productList .productList div h1 {
    top: 26.52519893899204vh;
    left: 1.563vw;
  }
  #productList .productList div:hover h1 {
    top: 240px;
  }

  #productList .productList div p {
    top: 290px;
    left: 1.563vw;
  }
  .about .block {
    width: 80%;
  }
  #about .about {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .technologies h4 {
    font-size: 50px;
  }
  .techIcon {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .techIcon .icon {
    display: flex;
    font-size: 130px;
    margin-right: 20px;
    margin-bottom: 30px;
  }
  .techIcon .icon:hover {
    font-size: 150px;
    transition: all 0.3s ease-in-out;
  }
  .containerInfo {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .columInfo {
    display: flex;
    width: 80%;
  }
  .wrapper {
    width: 80%;
  }
  .up {
    left: 80%;
  }
  .contact-form {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
  }
}
@media only screen and (max-width: 700px) and (min-width: 479px) {
  #titleFirst {
    height: 400px;
  }
  .h1Wrl {
    font-size: 20px;
    width: 80%;
    margin-bottom: 40px;
    text-align: center;
  }
  .h2Wrl {
    font-size: 15px;
    width: 80%;
    margin-bottom: 40px;
    text-align: center;
  }
  #productList h2 {
    font-size: 20px;
  }
  .menu {
    width: 100%;
  }
  .contactFo h1 {
    font-family: "Jost", sans-serif;
    color: white;
    font-size: 30px;
  }
  .contactFo h2 {
    font-family: "Jost", sans-serif;
    color: white;
    font-size: 18px;
  }
  .contactFo a {
    font-family: "Jost", sans-serif;
    color: white;
    font-size: 20px;
  }
  .contactDiv {
    display: flex;
    width: 100%;
    min-height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .contact-form {
    margin: 0 auto;
    display: flex;
    height: auto;
    flex-direction: column;
    width: 100%;
  }
  .btn {
    position: relative;
    background: none;
    text-align: center;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    width: 100%;
    border: none;
    font-size: 15px;
    cursor: pointer;
    text-transform: uppercase;
    height: 40px;
    font-family: "Jost", sans-serif;
  }
  .input-fields {
    display: flex;
    flex-direction: column;
    width: calc(100% - 4%);
    margin-right: 4%;
  }
  .msg {
    width: calc(100% - 4%);
  }
  #mailSend {
    width: 100%;
    margin-bottom: 60px;
  }
  .titleFirst {
    width: 75%;
  }
  .titleFirst h1 {
    color: white;
    font-size: 20px;
  }
  .fixedButton {
    font-size: 1em;
    left: -4%;
    border: none;
  }
  #productList {
    padding: 0;
    height: auto;
    width: 100%;
  }
  #productList .productList div {
    display: "flex";
    overflow: hidden;
    position: relative;
    width: 60%;
    height: 340px;
    border: 2px solid white;
    margin-right: 2%;
    margin-top: 2%;
    transition: all 300ms ease-in-out;
  }
  #productList .productList div h1 {
    top: 26.52519893899204vh;
    left: 1.563vw;
  }
  #productList .productList div:hover h1 {
    top: 240px;
  }
  #productList .productList div p {
    top: 290px;
    left: 1.563vw;
  }
  #productList .productList div h3 {
    left: 420px;
  }
  .about .block {
    width: 80%;
  }
  #about .about {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .technologies h4 {
    font-size: 50px;
  }
  .techIcon {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .techIcon .icon {
    display: flex;
    font-size: 130px;
    margin-right: 20px;
    margin-bottom: 30px;
  }
  .techIcon .icon:hover {
    font-size: 150px;
    transition: all 0.3s ease-in-out;
  }
  .containerInfo {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .columInfo {
    display: flex;
    width: 80%;
  }
  .wrapper {
    width: 80%;
  }
  .up {
    left: 80%;
  }
}
@media only screen and (max-width: 1200px) and (min-width: 960px) {
  .h1Wrl {
    font-size: 40px;
    width: 80%;
    margin-bottom: 60px;
    text-align: center;
  }
  .h2Wrl {
    font-size: 20px;
    width: 80%;
    margin-bottom: 40px;
    text-align: center;
  }
  #mailSend {
    width: 100%;
    margin-bottom: 60px;
  }
  .titleFirst {
    width: 75%;
  }
  .titleFirst h1 {
    color: white;
    font-size: 30px;
  }
  .fixedButton {
    font-size: 1em;
    left: -2%;
    border: none;
  }
  #productList {
    padding: 0;
    height: auto;
    width: 100%;
  }
  #productList .productList div {
    display: "flex";
    overflow: hidden;
    position: relative;
    width: 40%;
    height: 360px;
    border: 2px solid white;
    margin-right: 2%;
    margin-top: 2%;
    transition: all 300ms ease-in-out;
  }
  #productList .productList div h1 {
    top: 26.52519893899204vh;
    left: 1.563vw;
  }
  #productList .productList div:hover h1 {
    top: 240px;
  }
  #productList .productList div p {
    top: 290px;
    left: 1.563vw;
  }

  .technologies h4 {
    font-size: 50px;
  }
  .techIcon {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .techIcon .icon {
    display: flex;
    font-size: 130px;
    margin-right: 20px;
    margin-bottom: 30px;
  }
  .techIcon .icon:hover {
    font-size: 150px;
    transition: all 0.3s ease-in-out;
  }
  .containerInfo {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .columInfo {
    display: flex;
    width: 80%;
  }
  .wrapper {
    width: 80%;
  }
  .up {
    left: 90%;
  }
}
@media only screen and (max-width: 960px) and (min-width: 700px) {
  #titleFirst {
    height: 700px;
  }
  .h1Wrl {
    font-size: 30px;
    width: 80%;
    margin-bottom: 60px;
    text-align: center;
  }
  .h2Wrl {
    font-size: 15px;
    width: 80%;
    margin-bottom: 40px;
    text-align: center;
  }
  #productList h2 {
    font-size: 30px;
  }
  .contactDiv {
    display: flex;
    width: 100%;
    min-height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .contactFo h1 {
    font-family: "Jost", sans-serif;
    color: white;
    font-size: 30px;
  }
  .contactFo h2 {
    font-family: "Jost", sans-serif;
    color: white;
    font-size: 18px;
  }
  .contactFo a {
    font-family: "Jost", sans-serif;
    color: white;
    font-size: 20px;
  }
  #mailSend {
    width: 100%;
    margin-bottom: 60px;
  }
  .titleFirst {
    width: 75%;
  }
  .titleFirst h1 {
    color: white;
    font-size: 30px;
  }
  .fixedButton {
    font-size: 1em;
    left: -2%;
    border: none;
  }
  #productList {
    padding: 0;
    height: auto;
    width: 100%;
  }
  #productList .productList div {
    display: "flex";
    overflow: hidden;
    position: relative;
    width: 40%;
    height: 360px;
    border: 2px solid white;
    margin-right: 2%;
    margin-top: 2%;
    transition: all 300ms ease-in-out;
  }
  #productList .productList div h1 {
    top: 26.52519893899204vh;
    left: 1.563vw;
  }
  #productList .productList div:hover h1 {
    top: 240px;
  }
  #productList .productList div p {
    top: 290px;
    left: 1.563vw;
  }
  .about .block {
    width: 80%;
  }
  #about .about {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .technologies h4 {
    font-size: 50px;
  }
  .techIcon {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .techIcon .icon {
    display: flex;
    font-size: 130px;
    margin-right: 20px;
    margin-bottom: 30px;
  }
  .techIcon .icon:hover {
    font-size: 150px;
    transition: all 0.3s ease-in-out;
  }
  .containerInfo {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .columInfo {
    display: flex;
    width: 80%;
  }
  .wrapper {
    width: 80%;
  }
  .up {
    left: 90%;
  }
}
