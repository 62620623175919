.footer {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.pd {
  font-family: "Jost", sans-serif;
  font-size: 15px;
  color: white;
  margin-bottom: 0;
}
.linkSiteMap {
  font-family: "Jost", sans-serif;
  font-size: 15px;
  color: white;
}
